/* ================================================== */
/* Lokalocal
/* created by Lokalocal
/* ================================================== */

/* Table of contents


/* ================================================== */




/* import fonts icon */
/* ================================================== */
@import url("../fonts/font-awesome/css/font-awesome.css");
@import url("../fonts/elegant_font/HTML_CSS/style.css");
@import url("../fonts/et-line-font/style.css");

/* import fonts */
/* ================================================== */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@200;300;400;500;600;700;800&display=swap');
/*--------------------------------------------------------------
3.0 - Typography
--------------------------------------------------------------*/
@font-face {
  font-family: "CircularThin";
  src: url("../fonts/CircularXXWeb-Light.woff") format("woff");
}
@font-face {
  font-family: "CircularThin";
  src: url("../fonts/CircularXXWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "CircularMedium";
  src: url("../fonts/CircularXXSub-RegularSubset.woff") format("woff");
}
@font-face {
  font-family: "CircularMedium";
  src: url("../fonts/CircularXXSub-RegularSubset.woff2") format("woff2");
}

@font-face {
  font-family: "CircularBold";
  src: url("../fonts/CircularXXWeb-Medium.woff") format("woff");
}
@font-face {
  font-family: "CircularBold";
  src: url("../fonts/CircularXXWeb-Medium.woff2") format("woff2");
}

h1,h2,h3,h4,h5{
  font-family: "CircularBold";
  font-weight: normal !important;
}
.half-color{
	opacity: .7;
}
/*--------------------------------------------------------------
end Typography
--------------------------------------------------------------*/

/* icon scroll */
.icon-scroll,
.icon-scroll:before {
    position: absolute;
    left: 48%;
    bottom: 30px;
}
.icon-scroll {
    display: flex;
    justify-content: center;
    width: 26px;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.8);
    z-index: 1;
    border-radius: 25px;
    height: 38px;
}
.icon-scroll:before {
    content: '';
    width: 3px;
    height: 6px;
    background: rgba(255,255,255,.9);
    left: 12px;
    top: 8px;
    border-radius: 4px;
    -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
          animation-name: scroll;
}
@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}
@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
}

/* video home */
.mainbgvideo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

/* modal */
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 0px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}
.modal-header .close {
  float: right;
  font-size: .8rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
  padding: 1.3rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.modal-content {
  padding: 0 5%;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0 1rem;
  line-height: 2.3;
}

/* body */
/* ================================================== */
* {
--body-font: "CircularMedium", Helvetica, Arial, sans-serif;
--title-font: "CircularBold", Helvetica, Arial, sans-serif;
}
:root {
  --primary-color: #ff5876;
}
.colorpink {
  color: #ff5876;
}

html {
overflow-x: hidden;
padding: 0;
margin: 0;
background: #fff;
}

body {    
font-family: var(--body-font);
font-size: 16px;
font-weight: 400;
color: #333;
line-height: 30px;
padding: 0;
margin: 0;
line-height: 26px;
word-spacing: 0px;
}
body.whitebg {
  background: #fff;
}
.content-wrapper {
position: relative;
overflow: hidden;
}
section {
position: relative;
background: #fff;
padding: 90px 0;
}
section.no-top{
padding-top: 0;
}
section.no-bottom{
padding-bottom: 0;
}
section.gray{
background: rgba(0,0,0,.025);
}
.relative{
position: relative;
}

.btn:focus{
box-shadow: 0 0 0 .25rem rgba(13,110,253,0);
}
.btn{
font-size: 14px;
border-radius: 2px;
padding: .375rem .95rem;
background: #333;
color: #fff;
transition: all 0.3s ease;
}
.btn:hover{
color: #fff;
background: #444;
transition: all 0.3s ease;
}
.pink-button {
font-family: "CircularBold";
font-size: 15px;
width: max-content !important;
color: #fff;
background-color: #ff5876;
font-weight: 400;
border: 0;
border-radius: 2px;
padding: 2px 26px !important;
text-decoration: none;
transition: all 0.3s ease;
}
.pink-button:hover{
background-color: #ff7d92;
color: #fff;
transition: all 0.3s ease;
}
.pink-button i{
  font-size: 11px;
  padding-right: 5px;
  position: relative;
  top: -1px;
}
.jumbotron{
width: 100%;
height: auto;
padding: 210px 0;
background-size: cover;
display: flex;
align-items: center;
}
.jumbotron:before {
position: absolute;
content: "";
width: 100%;
height: 100%;
top: 0;
left: 0;
background: rgba(0,0,0,.085);
}
.jumbotron.detail{
	padding: 160px 0;
}
.jumbotron.error404{
  padding: 160px 0;
}
.jumbotron.error404:before {
  background: rgba(0,0,0,0);
}
.jumbotron.detail h2{
font-size: 3rem;
text-shadow: 0 6px 12px rgba(0,0,0,.08);
}
.jumbotron.detail .relative{
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
}
.jumbotron.detail .detailbtn{
	max-width: 1320px;
	margin-top: auto;
	padding-left: 10%;
	padding-bottom: 15px;
	position: relative;
	left: 0;
	bottom: 0;
}
.jumbomain{
	text-align: center;
	color: #fff;
	position: relative;
}
.jumbotron.error404 .jumbomain{
  color: #333;
}
.jumbomain h1{
	font-family: "CircularBold";
	font-size: 6rem;
	text-shadow: 0 6px 12px rgba(0,0,0,.08);
}
.jumbomain p{
	font-family: "CircularMedium";
	font-size: 1.3rem;
	letter-spacing: .3px;
	line-height: 1.3;
	text-shadow: 0 6px 12px rgba(0,0,0,.08);
}

/* header */
/* ================================================== */
.p-3-vh {
display: flex;
justify-content: space-between;
align-items: center;
}
.p-3-vh > *{
flex-shrink: unset;
max-width: max-content;
width: max-content;
}
.subheader{
width: 100%;
font-size: .8rem;
position: relative;
top: 0;
display: block;
color: #fff;
background: #313840;
padding: 0 10px;
z-index: 1001;
}
.subheader.show{
position: fixed;
top: -100%;
transition: all 1.8s ease-in-out;
}
.subheader span.column{
padding-right: 20px;
}
.subheader span.column span{
padding-right: 5px;
}
.subheader span.column:last-child{
padding-right: 0;
}
.language{
cursor: pointer;
float: right;
margin: 5px 0;
}
.language-active{
width: max-content;
padding: 4px 15px;
border: 1px solid rgba(255,255,255,.1);
border-radius: 20px;
}
.language-active:after{
font-family: "FontAwesome";
display: inline-block;
content:"\f078";
position: relative;
top: -1px;
font-size: .6rem;
padding-left: 15px;
opacity: .5;
}
.subheader .language-list {
display: none;
position: absolute;
top: 40px;
right: 0;
padding: 5px 30px 15px 30px;
margin: 0;
list-style: none;
line-height: 2;
background: #495360;
border-bottom-right-radius:4px;
border-bottom-left-radius:4px;
}
.centeredleft {
display: flex;
align-items: center;
justify-content: left;
}
header{
top: 0px;
left: 0px;
position: fixed;
z-index: 999;
}
header.init {
margin-bottom: 0px;
width: 100%;
padding: 0;
background: rgba(255,255,255,0);
box-shadow: 0px 10px 60px rgba(0, 0, 0, 0);
border-bottom: 1px solid rgba(0,0,0,0);
-webkit-transition: all .5s cubic-bezier(.165, .84, .44, 1);
-moz-transition: all .5s cubic-bezier(.165, .84, .44, 1);
transition: all .5s cubic-bezier(.165, .84, .44, 1);
}
header.bg{
top: 40px;
padding: 0 0;
}
header.show {
position: fixed;
top: 0px;
left: 0px;
padding: 0 0;
box-shadow: 0px 10px 60px rgba(0, 0, 0, .1);
background: #fff !important;
border-bottom: 1px solid rgba(0,0,0,.1);
-webkit-transition: all .5s cubic-bezier(.165, .84, .44, 1);
-moz-transition: all .5s cubic-bezier(.165, .84, .44, 1);
transition: all .5s cubic-bezier(.165, .84, .44, 1);
}
/*logo*/
.logo {
position: relative;
top: 0px;
left: 0px;
margin: 10px 0;
margin: 0px;
width: max-content;
height: auto;
z-index: 2;
}
.logo img{
max-height: 25px;
}
.logo img.show{
display: none;
}
header.show img.show{
display: block;
}
header.show img.init{
display: none;
}
/* main menu */
.white nav {
position: relative;
float: right;
top: 2px;
z-index: 999;
}
.white nav ul {
font-size: 0;
padding: 0;
margin: 0;
list-style: none;
position: relative;
}
.white nav ul li {
display: inline-block;
margin: 0;
}
.white nav ul li:last-child{
margin-right: 0px;
border-bottom: 0px;
}
.white nav ul li .input-group {
top: 15px;
margin-left: 20px;
width: 220px;
}
.white nav ul li .input-group button {
display: inherit;
padding: 0;
line-height: 0px;
border: none;
}
.white nav ul li .input-group i {
margin-left: 0px;
}
.white nav a {
font-family: "CircularBold";
color: rgba(255,255,255,.8);
display: block;
padding: 26px 15px 26px 15px;
font-size: 15px;
letter-spacing: .1px;
text-decoration: none;
-webkit-transition: all .1s ease-in-out;
-moz-transition: all .1s ease-in-out;
transition: all .1s ease-in-out;
}
.white nav a:hover {
color: #fff;
-webkit-transition: all .1s ease-in-out;
-moz-transition: all .1s ease-in-out;
transition: all .1s ease-in-out;
}
.white nav a.border-rad {
  border-radius: 20px;
  padding: 2px 16px;
  border: 2px solid #ff5876;
}
.sticky .white nav a, 
.sticky span.span-drop::after, 
.sticky .menu-right .iconright li a{
	color: #333 !important;
}
.sticky .menu-right .iconright li a i{
	color: var(--primary-color);
}
.white nav a:hover::before {
display: inline-block;
position: absolute;
max-width: 35px;
color: rgba(255, 255, 255, 0);
border-bottom: 2px solid rgba(255, 255, 255, 1);
-webkit-transition: max-width 0.5s;
-moz-transition: max-width 0.5s;
transition: max-width 0.5s;
}
.white nav a::before {
position: absolute;
overflow: hidden;
max-width: 0;
border-bottom: 2px solid rgba(255, 255, 255, 0);
color: rgba(255, 255, 255, 0);
content: attr(data-hover);
-webkit-transition: max-width 0.5s;
-moz-transition: max-width 0.5s;
transition: max-width 0.5s;
white-space: nowrap;
}
.white nav a.actived {
color: #ddd !important;
}
.white nav ul ul {
display: none;
position: absolute;
top: 60px;
color: #333;
border-radius: 4px;
overflow: hidden;
box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
}
.white nav ul li:hover>ul {
display: inherit;
-webkit-animation-name: animfadeInUpmenu;
animation-name: animfadeInUpmenu;
-webkit-animation-duration: .3s;
animation-duration: .3s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
}
.white nav ul ul li {
width: max-content;
float: none;
display: list-item;
background: #fff;
box-shadow: 5px 5px 2px rgba(23, 36, 52, .05);
position: relative;
}
.white nav ul li i {
margin-left: 10px;
}
.white nav ul ul li a {
font-weight: 400;
color: #111;
font-size: 13px;
line-height: 5px;
padding: 15px 20px 15px 20px;
}
.white nav ul ul li a:hover {
color: #fff !important;
background: var(--primary-color);
}
.white nav ul ul li a.active {
background: #f8f8f8;
}
.white nav ul ul li:last-child {
border-bottom: none;
}
.white nav ul ul ul li {
position: relative;
top: -75px;
left: 230px;
}
.white>nav>ul>li.has-children>a:after {
content: '\f107';
font-family: 'FontAwesome';
margin-left: 10px;
}
li>a:only-child:after {
content: '';
}
span.span-drop {
opacity: .8;
position: relative;
right: 0;
top: 1px;
float: right;
cursor: pointer;
}
span.span-drop:after {
content: '\f107';
font-family: 'FontAwesome';
color: #333;
width: 20px;
height: 20px;
margin-left: 2px;
text-align: center;
line-height: 20px;
display: inline-block;
}
a.actived span.span-drop:after{
color: #ddd !important;
}
.nav-icon{
display: none;
}
a.icon-login {
  width: max-content;
  position: relative;
  margin: 26px 15px 26px 15px;
  padding: 0 !important;
  border-bottom: 0px !important;
}
a.icon-login i{
  font-size: 1.1rem;
  margin-left: 0 !important;
}
.loginicon {
  width: 5px;
  height: 5px;
  border-radius: 8px;
  background: #ff5876;
  position: absolute;
  right: 1px;
  top: 4px;
}

/* menu right */
.menu-right {
padding: 36px 0;
}
.menu-right .social-icons{
margin: 0;
}
.menu-right .social-icons a{
color: #fff;
font-size: 11pt;
}
.menu-right{
width: max-content;
padding: 0 15px;
}
.menu-right .iconright{
position: relative;
top: 4px;
padding: 0;
margin: 0;
list-style: none;
font-size: 0;
}
.menu-right .iconright li{
display: inline-block;
}
.menu-right .iconright li:first-child{
padding-right: 20px;
}
.menu-right .iconright li:last-child a{
margin-right: 0px;
}
.menu-right .iconright li a{
font-weight: 600;
font-size: 15px;
color: #fff;
text-decoration: none;
padding: 35px 10px;
}
.menu-right .iconright li a i{
padding-left: 5px;
color: #fff;
}
.menu-right .iconright li.menumobile{
display: none;
padding: 0;
}
#showmobile{
display: none;
position: fixed;
right: 15px;
top: 17.5px;
font-size: 1rem;
color: #111;
cursor: pointer;
z-index: 999;
}

/* menu extended */
#topnav.relative .topmenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}


/* owl */
.vr-catgor{
padding-top: 30px;
}
.owl-carousel .owl-stage-outer{
width: 101%;
--bs-gutter-x: 0;
padding-right: 0;
padding-left: 0;
}
.owl-theme .owl-nav{
position: absolute;
bottom: 55%;
width: 100%;
}
.owl-carousel .owl-nav .owl-prev i, .owl-carousel .owl-nav .owl-next i{
font-size: .9rem;
color: #666;
}
.owl-carousel .owl-nav .owl-next i{
left: 2px;
position: relative;
}
.owl-carousel .owl-nav .owl-prev i{
right: 1px;
position: relative;
}
.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next{
background: none!important;
}
.owl-carousel .owl-nav .owl-prev{
left: -41px;
position: absolute;
outline: 0;
}
.owl-theme .owl-nav .disabled{
opacity: 0 !important;
cursor: pointer;
}
.owl-carousel .owl-nav .owl-next{
right: -41px;
position: absolute;
outline: 0;
}
.owl-nav{
-webkit-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.owl-carousel:hover .owl-nav {
opacity: 1;
-webkit-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.owl-theme .owl-nav [class*="owl-"]{
width: 32px;
height: 32px;
line-height: 32px;
border-radius: 32px;
padding: 0;
text-align: center;
background: #fff;
border: 1px solid rgba(0,0,0,.1);
}
.owl-theme .owl-nav [class*="owl-"]:hover{
background: rgba(0,0,0,.3);
border: 1px solid rgba(0,0,0,.1);
}
.vr-catgor a.item {
position: relative;
left: -.5px;
border: 1px solid rgba(0,0,0,.1);
display: block;
width: 100%;
color: rgba(0,0,0,.5);
text-decoration: none;
border-radius: 8px;
overflow: hidden;
}
.img-item img{
width: 100%;
height: 450px;
object-fit: cover;
}
.img-item img.third{
  height: 290px;
}
.img-item .imgrounded {
position: relative;
height: 100%;
overflow: hidden;
}
.imgrounded .imghover {
opacity: 0;
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
transition: all 0.3s ease;
}
.imgrounded .imghover .bgvideo {
position: absolute;
right: 0;
bottom: 0;
min-width: 100%;
min-height: 100%;
}
.img-item:hover .imgrounded .imghover {
  opacity: 1;
  transition: all 0.6s ease;
  animation: scaleanim 3s ease;
}
@keyframes scaleanim {
    0%   {transform: scale(1.03);}
    100%   {transform: scale(1);}
 }
.vr-catgor .subtext{
font-size: 13px;
}
.vr-catgor .desc{
font-size: 1.1rem;
font-family: "CircularBold";
color: rgba(0,0,0,.8);
}
.vr-catgor .icon{
display: flex;
}
.vr-catgor .icon .main-icon{
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 14px;
  margin-right: 10px;
}
.main-icon span{
font-size: 13px;
}
.vr-catgor .icon .main-icon i{
color: #ff7d92;
font-size: 11px;
}
.video video{
position: relative;
width: 100%;
height: auto;
margin: 15px 0 30px;
}
.flybox{
text-align: center;
top: 90px;
z-index: 1;
padding: 30px;
margin-bottom: 20px;
background: rgb(255, 255, 255) none repeat scroll 0% 0%;
box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
}
.flybox span{
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	line-height: 40px;
	text-align: center;
	background: #efefef;
	margin: 0 2.5px;
}
ul{
	padding: 0;
	margin: 0;
}
ul.half-color li{
	display: inline-block;
	width: max-content;
	margin-right: 15px;
	margin-bottom: 10px;
}
ul.half-color li i{
	opacity: 1;
	color: #ff5876;
	padding-right: 2px;
}

/* footer */
footer{
padding: 60px 0 30px;
border-top: 1px solid rgba(0,0,0,.1);
}
footer .headingfooter {
color: #333;
font-size: 16px;
font-family: "CircularBold";
}
footer ul{
font-size: 14.5px;
padding-left: 0;
}
footer ul li{
list-style: none;
}
footer li a {
 color: rgba(0,0,0,.5);
 text-decoration: none;
 transition: all 0.3s ease;
}
footer li a:hover{
color: rgba(0,0,0,.7);
transition: all 0.3s ease;
}
footer li i{
width: 20px;
color: rgba(0,0,0,.8);
}
footer .bottom{
margin-top: 30px;
color: gray;
font-size: 14px;
}
footer .bottom img {
  margin-top: -4px;
  margin-right: 5px;
}
footer select.form-control {
  box-shadow: none;
  padding: 9px 12px;
  font-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 80%;
  background: #fefefe url(../img/dropdown_arrow.png) no-repeat !important;
    background-position-x: 0%;
    background-position-y: 0%;
  background-position-x: 0 !important;
  background-position-y: 0 !important;
  background-position: right 10px center !important;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13,110,253,0);
}
footer .form-group {
border: 1px solid #ebebeb !important;
}
footer .pink-button {
font-family: "CircularBold";
font-size: 15px;
width: max-content !important;
color: #fff;
background-color: #ff5876;
font-weight: 400;
border: 0;
border-radius: 2px;
padding: 6px 26px !important;
transition: all 0.3s ease;
}
footer .pink-button:hover{
background-color: #ff7d92;
color: #fff;
transition: all 0.3s ease;
}

/* audio tour */
#topnav{
  width: 100%;
}
#topnav ul li{
  list-style: none;
}
#topnav.relative {
  position: fixed;
  background: #fff;
  color: #272727;
  padding: 25px 5% 10px;
  z-index: 999;
}
#topnav.relative header{
  position: relative;
  display: block;
}
#topnav.relative .topmenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}
#topnav.relative .topmenu .brand {
  width: 160px;
  height: auto;
}
#topnav.relative .topmenu .brand a img{
  width: 100%;
}
#topnav.relative .topmenu .menu {
  margin-left: 30px;
  display: flex;
  align-items: center;
}
#topnav.relative .spacer-menu {
  position: relative;
  left: -5.5%;
  display: block;
  width: 100vw;
  height: 1px;
  background: rgba(0,0,0,.1);
}
#topnav.relative .botmenu {
  padding: 25px 0 5px;
}
#topnav.relative .pink-button {
  width: max-content;
  /* padding: 6px 30px !important; */
  background: #f71d43;
  border-radius: 4px;
  color: #fff !important;
  margin-top: 5px;
}
#topnav.relative .botmenu .menubot li {
  display: inline-block;
  margin: 5px;
}
#topnav.relative .botmenu .menubot li a {
  display: block;
  font-size: .9rem;
  color: #444;
  font-family: "CircularMedium";
  text-decoration: none;
  background: #fefefe;
  padding: 5px 20px;
  border: 1px solid #c3c3c3;
  border-radius: 30px;
}
#mainmap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #ddd;
}
#mainmap iframe{
  width: 100%;
  height: 100%;
}
#mapcontent .contentmap {
  padding: 170px 15px 15px 15px;
  background: #fff;
  color: #272727;
}
#mapcontent .contentmap .topmainmap {
  padding: 30px 0;
}
#mapcontent .contentmap .topmainmap .heading {
  font-size: 2rem;
  font-family: "CircularBold";
  padding: 15px 0;
}
#mapcontent .contentmap .topmainmap .subheading {
  font-size: .9rem;
}
#mapcontent .contentmap .botmainmap .detailmap h5 {
  font-size: 1rem;
  margin-bottom: 0;
}
#mapcontent .contentmap .botmainmap .detailmap p {
  font-size: .91rem;
  margin-bottom: 5px;
}
#mapcontent .contentmap .botmainmap .detailmap p.stories {
  font-size: .85rem;
  opacity: .6;
}
#mapcontent .contentmap hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  border: 0;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0px;
  border-top: 1px solid rgba(0,0,0,.1);
}
#mapcontent .contentmap .botmainmap {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 30px 0;
  text-decoration: none;
  color: #272727;
  transition: all .3s ease;
}
#mapcontent .contentmap .botmainmap .imgrounded {
  position: relative;
  border-radius: 4px;
  width: 250px;
  height: 230px;
  overflow: hidden;
  margin-right: 15px;
}
.contentmap .botmainmap .imgrounded img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#mapcontent .contentmap .botmainmap .detailmap{
  position: relative;
  width: 80%;
  display: block;
}
.imgrounded .imghover {
  display: none;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
}
#mapcontent .contentmap .botmainmap:hover .imgrounded .imgcov {
  opacity: 0;
  transition: all .3s ease;
}
#mapcontent .contentmap .botmainmap:hover .imgrounded .imghover {
  display: block;
  animation: scaleanim 1s ease;
}
@keyframes scaleanim {
  0%   {transform: scale(1.05);}
  100% {transform: scale(1);}
}
#mapcontent .contentmap .botmainmap .detailmap .marker {
  position: absolute;
  right: 15px;
  top: 0;
  width: 15px;
  height: 15px;
}
#mapcontent .contentmap .botmainmap .detailmap .marker img{
  width: 100%;
  height: auto;
}
#fullblock {
  position: fixed;
  width: 105%;
  height: 100%;
  top: -100%;
  right: -5%;
  color: #272727;
  background: rgba(255,255,255,.98);
  display: flex;
  justify-content: flex-start;
  z-index: 9999;
  overflow: hidden;
  transition: all .6s ease;
}
#fullblock.show {
  top: 0;
}
#fullblock .closefullblock {
  position: absolute;
  right: 8%;
  top: 3%;
  cursor: pointer;
  z-index: 9;
}
#fullblock .imgblock {
  width: 100%;
  height: 100%;
  position: relative;
}
#fullblock .imgblock #bgvideo{
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
#fullblock .contentblock {
  width: 100%;
  height: 98%;
  padding: 60px 9vw 60px 5vw;
  overflow-y: auto;
}
#fullblock .powered {
  font-size: .89rem;
  color: #999;
  display: flex;
  align-items: center;
}
#fullblock .powered img {
  width: 15px;
  height: 100%;
  margin-left: 15px;
}
.grey {
  background: #ddd;
}
#topnav.relative .topmenu .menu .mainmenunew li a.icon-login {
  position: relative;
  background: #f3f3f3;
  padding: 10px 15px !important;
  border: 1px solid #c3c3c3 !important;
  border-radius: 20px;
}
#topnav.relative .topmenu .menu .mainmenunew li a {
  font-size: .9rem;
  color: #272727;
  font-family: "CircularBold";
  text-decoration: none;
}
#topnav.relative .topmenu .menu .mainmenunew li.dropdown a i {
  font-size: .9rem;
  margin-left: 5px !important;
}
#topnav.relative .topmenu .menu .mainmenunew li a.icon-login i.big {
  font-size: 1.3rem;
  position: relative;
  top: 4px;
  margin-left: 5px;
}
#topnav.relative .topmenu .menu .mainmenunew li.dropdown {
  height: 45px;
  position: relative;
  top: 8px;
  display: inline-block;
  margin: 0 15px;
}
#topnav.relative .topmenu .menu .mainmenunew li a.icon-login .loginicon{
  top: 6px;
  right: 15px;
  width: 6px;
  height: 6px;
}
#topnav.relative .topmenu .menu .mainmenunew li.dropdown ul {
  display: none;
  position: absolute;
  top: 45px;
  right: 0;
  padding: 5px 15px;
  margin: 0;
  background: #ff5876;
  width: max-content;
  z-index: 999;
  border-radius: 5px;
  box-shadow: 2px 2px 30px 0 rgba(20,20,20,.1);
}
#topnav.relative .topmenu .menu .mainmenunew li.dropdown ul li a{
  color: #fff !important;
}
#topnav.relative .topmenu .menu .mainmenunew li.dropdown:hover > ul {
  display: inherit;
}
#mapcontent .contentmap.purchases{
  padding: 15px;
  background: #fff;
  color: #272727;
  margin-top: 120px;
}
#mapcontent .contentmap .botmainmap .detailmap .subheading {
  font-size: .9rem;
  margin-bottom: 10px;
}
#mapcontent .contentmap .botmainmap .detailmap .subheading::after {
  display: block;
  content: '';
  width: 30px;
  height: 2px;
  background: #ff5876;
  margin-top: 5px;
}
#mapcontent .contentmap .botmainmap .detailmap .mainbutton {
  position: absolute;
  right: 15px;
  top: 0;
}
#mapcontent .contentmap .botmainmap .detailmap .mainbutton a {
  font-size: .8rem;
  font-weight: 700;
  display: inline-block;
  width: max-content;
  height: auto;
  padding: 6px 15px;
  border-radius: 2px;
  background: #ff5876;
  text-decoration: none;
  color: #fff;
  margin-right: 5px;
}
#mapcontent .contentmap .botmainmap .detailmap .mainbutton a.grey{
  background: #999;
}
#mainmap {
  /* position: fixed; */
  position: relative;
  height: 100%;
  background: #ddd;
}
#mainmap .bgover {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: rgba(0,0,0,.2);
}
#activationpage {
  cursor: default;
  position: relative;
  padding: 15px 0 60px;
  color: #fff;
  background: none;
  text-shadow: 0 0 6px rgba(0,0,0,.83);
}
#activationpage.noselect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* media all */
/* ================================================== */
@media only screen and (max-width: 1199px) {
	header.init{
	background: #fff;
	padding: 20px 0;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	box-shadow: 0px 10px 60px rgba(0, 0, 0, .1);
	}
	.p-3-vh{
	display: block;
	}
	.logo{
	padding: 0;
	left: 15px;
	}
	.logo img{
	max-height: 20px !important;
	}
	.logo img.show {
	display: block;
	}
	.logo img.init {
	display: none;
	}
	.menu-right{
	position: absolute;
	top: 15px;
	right: 0;
	height: auto;
	display: flex;
	align-content: center;
	align-items: center;
	z-index: 9999;
	}
	.menu-right .iconright li.menumobile{
	display: inline-block;
	}
	.menu-right .iconright li a{
	color: #333;
	}
	.menu-right .iconright li a i{
	color: var(--primary-color);
	}
	#main-menu{
	display: none;
	padding-top: 20px;
	width: 100%;
	max-width: 100%;	
	}
	.white nav{
	float: left;
	width: 100%;
	}
	.white nav ul{
	width: 100%;
	}
	.white nav ul li{
	display: block;
	padding-left: 15px;
	}
	.white nav ul ul{
	position: relative;
	top: 0;
	box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0);
	}
	.white nav a{
	color: #333;
	padding: 6px 0 8px 0;
	border-bottom: 1px solid rgba(0,0,0,.1);
	}
	.white nav a:hover {
	color: #444;
	}
	.white nav ul li:hover > ul{
	display: none;
	}
  .white nav a.border-rad {
    width: max-content;
    border-bottom: 2px solid #ff5876 !important;
    padding: 0 16px;
    margin: 5px 0;
}
	.white nav ul ul li{
	width: 100%;
	padding: 2px 0 2px 0;
	border-bottom: 1px solid rgba(0,0,0,.1);
	}
	.white nav a:last-child{
		border-bottom: 1px solid rgba(0,0,0,0);
	}
	span.span-drop::after{
	color: #333;
	}
	#showmobile{
	display: block;
	}
  .mobilehide {
    display: none;
  }
}
@media only screen and (max-width: 580px) {
  body{
    overflow: hidden;
  }
	.vr-catgor, .vr-catgor.p-0{
    padding-top: 15px !important;
		padding-right: 10px !important;
    padding-left: 10px !important;
	}
  .owl-theme .owl-nav [class*="owl-"]{
    border: 1px solid rgba(0,0,0,0);
  }
  .owl-carousel .owl-nav .owl-next{
    right: -30px;
  }
  .owl-carousel .owl-nav .owl-prev{
    left: -30px;
  }
	.jumbotron{
		padding: 120px 0;
	}
	.jumbomain h1{
		font-size: 3rem;
	}
}